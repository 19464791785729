// import node module libraries
import {
  createBookTextAction,
  editBookTextAction,
  getBookTextAction,
} from "actions/books";
import { getQuestionByCatAction } from "actions/quiz";
import { Fragment, useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Tab,
  Breadcrumb,
  Nav,
  Form,
  Button,
} from "react-bootstrap";
import { Edit } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Questions from "./Questions";
import QuestionModal from "./QuestionModal";

const BookDetails = () => {
  const location = useLocation();
  const lineColor = [
    "#ef476f",
    "#f78c6b",
    "#ffd166",
    "#06d6a0",
    "#118ab2",
    "#073b4c",
  ];
  const searchParams = new URLSearchParams(location.search);
  const book_title = searchParams.get("book_title");
  const dispatch = useDispatch();
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [editText, setEditText] = useState(false);
  const { bookText } = useSelector((state) => state.books);
  const { storyQuestions } = useSelector((state) => state.quizs);

  const totalQuestions = storyQuestions?.length;

  const teacherStats = storyQuestions?.reduce((acc, question) => {
    const teacher = question?.checkedBy;
    acc[teacher] = (acc[teacher] || 0) + 1;
    return acc;
  }, {});
  const questionsCount = Object.entries(teacherStats).map(
    ([teacher, count]) => ({
      teacher,
      count,
      percentage: ((count / totalQuestions) * 100).toFixed(2) + "%",
    })
  );

  const [text, setText] = useState("");

  useEffect(() => {
    if (id) {
      dispatch(getBookTextAction(id));
      dispatch(getQuestionByCatAction({ category: book_title }));
    }
  }, [id]);

  const handleClose = () => {
    setEditText(false);
    setShow(false);
  };
  useEffect(() => {
    if (bookText?.id) {
      setText(bookText.text);
    } else {
      setText("");
    }
  }, [bookText, id]);
  const handleCreateText = () => {
    if (bookText?.id) {
      dispatch(editBookTextAction(id, { text }, handleClose));
    } else {
      dispatch(createBookTextAction({ book_id: id, text }, handleClose));
    }
  };

  return (
    <Fragment>
      <QuestionModal show={show} handleClose={handleClose} title={book_title} />
      <Tab.Container defaultActiveKey="notapproved">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
              <div className="mb-3 mb-md-0">
                <h1 className="mb-1 h2 fw-bold">Book Details</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">Books management</Breadcrumb.Item>
                  <Breadcrumb.Item active>Book details</Breadcrumb.Item>
                </Breadcrumb>
                <div className="d-flex">
                  <p>Verfied by:</p>
                  {questionsCount?.map((count, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <p style={{ marginLeft: "10px" }}>{count?.teacher} :</p>
                      <p
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {count?.percentage}
                      </p>
                    </div>
                  ))}
                  {/* <h5>Total Questions : {totalQuestions}</h5> */}
                </div>
              </div>
            </div>
            <Col className="d-flex justify-content-end">
              <div style={{ marginBottom: "20px" }}>
                {/* <AddFollowSession show={show} setShow={setShow} /> */}
                <Button onClick={() => setShow(true)}> Import Questions</Button>
              </div>
            </Col>
          </Col>
        </Row>
        <Card.Header className="border-bottom-0 p-0 bg-white">
          <Nav className="nav-lb-tab">
            <Nav.Item>
              <Nav.Link eventKey="notapproved" className="mb-sm-3 mb-md-0">
                Questions
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="approved" className="mb-sm-3 mb-md-0">
                Head Words
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body className="p-0">
          <Tab.Content>
            <Tab.Pane eventKey="approved" className="pb-4">
              {!editText ? (
                <div
                  style={{
                    padding: "2%",
                    backgroundColor: "white",
                    margin: "10px",
                    borderRadius: "5px",
                    position: "relative",
                    height: "40vh",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                      color: "#754FFE",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={() => setEditText(true)}
                  >
                    <Edit
                      style={{ color: "#754FFE" }}
                      size="20px"
                      className="dropdown-item-icon"
                    />
                    Edit
                  </div>{" "}
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#1E2960",
                    }}
                  >
                    {" "}
                    {text}
                  </p>
                  {text == "" && <p> No head Words availbe yet</p>}
                </div>
              ) : (
                <div>
                  <Form.Group style={{ marginTop: "10px" }}>
                    <Form.Control
                      as="textarea"
                      onChange={(e) => setText(e.target.value)}
                      value={text}
                      style={{ height: "40vh" }}
                    />
                  </Form.Group>
                  <Button
                    style={{ marginTop: "10px" }}
                    variant="primary"
                    onClick={() => handleCreateText()}
                  >
                    {bookText?.id ? "Update " : " Create"}
                  </Button>
                </div>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="notapproved" className="pb-4">
              <Questions questions={storyQuestions} />
            </Tab.Pane>
          </Tab.Content>
        </Card.Body>
      </Tab.Container>
      <ToastContainer />
    </Fragment>
  );
};

export default BookDetails;
