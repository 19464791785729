import { GET_GAME_RESULTS } from "actions/constants";

const gamesReducer = (state = { gameResults: [] }, action) => {
  switch (action.type) {
    case GET_GAME_RESULTS:
      return { ...state, gameResults: action?.payload };

    default:
      return state;
  }
};

export default gamesReducer;
