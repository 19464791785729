import { getAllGameResults, getGameResults } from "api";
import { GET_GAME_RESULTS, LOADING } from "./constants";

export const getGameResultsAction = (game, book_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getGameResults(game, book_id);

    dispatch({ type: GET_GAME_RESULTS, payload: data });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const getAllGameResultsAction = (book_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAllGameResults(book_id);

    dispatch({ type: GET_GAME_RESULTS, payload: data });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
