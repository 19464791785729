import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllSessions,
  getSessionById,
  editSession,
  deleteSession,
  createSession,
  getSessionsFromBook,
  getSessionByTeacher,
  getReaderInSession,
  presenceAPI,
  createNotification,
  getUnits,
  createUnit,
  createNotificationUser,
  getJwtToken,
} from "api";
import {
  GET_ALL_SESSIONS,
  GET_SESSION,
  EDIT_SESSION,
  DELETE_SESSION,
  CREATE_SESSION,
  GET_SESSIONS_FROM_BOOK,
  LOADING,
  GET_SESSIONS_BY_TEACHER,
  GET_READER_IN_SESSION,
  PRESENCE_ACTION,
  FETCH_ALL_UNITS,
  CREATE_UNIT,
} from "./constants";

export const fetchAllSessionsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAllSessions();
    console.log(data);
    dispatch({ type: GET_ALL_SESSIONS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const fetchSessionAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getSessionById(id);
    dispatch({ type: GET_SESSION, payload: data?.data.session });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const editSessionAction =
  (fromData, handleClose, setSessionId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await editSession(fromData);

      dispatch({ type: EDIT_SESSION, payload: data.session });
      handleClose();
      setSessionId(null);
      toast.success(data.data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const deleteSessionAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await deleteSession(id);

    dispatch({ type: DELETE_SESSION, payload: id });
    toast.success(data.data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const createSessionAction =
  (fromData, handleClose, handleCloseLeave, leave) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createSession(fromData);
      const noti = {
        title: data.session.name,
        desc: "New session has been created ",
        link: `https://iread.education/landings/course-lead/${data.session.book_id}`,
        cat: "657826821ee2d4636399e519",
        target: data.session.pack_id,
      };
      const notiUser = {
        title: data.session.name,
        desc: "New session created for you ",
        link: `https://admin.iread.education/teacher-dashboard/sessions_details?session_id=${data.session.id}`,
        cat: "657180fe97959a4a3ee8d1d8",
        target: data.session.teacher_id,
      };

      await createNotification(noti);
      const { data: teacherNoti } = await createNotification(notiUser);
      await createNotificationUser({
        user_id: teacherNoti.target,
        notification_id: teacherNoti._id,
      });

      dispatch({ type: CREATE_SESSION, payload: data.session });
      if (leave === 1) {
        handleCloseLeave();
      } else {
        handleClose();
      }
      toast.success(data.message, {
        autoClose: 1000,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message);
      }
      dispatch({ type: LOADING, payload: false });
    }
  };
export const fetchSessionFromPackAction = (book_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getSessionsFromBook(book_id);
    dispatch({ type: GET_SESSIONS_FROM_BOOK, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      dispatch({ type: GET_SESSIONS_FROM_BOOK, payload: { sessions: [] } });
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};

export const getSessionByTeacherAction = (teacher_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getSessionByTeacher(teacher_id);
    dispatch({ type: GET_SESSIONS_BY_TEACHER, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};

export const getReaderInSessionAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await getReaderInSession(id);
    dispatch({
      type: GET_READER_IN_SESSION,
      payload: data.session_follow_requests,
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_READER_IN_SESSION, payload: [] });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const presentAction = (id, session_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const presence = { presence: false };
    const data = await presenceAPI(id, session_id, presence);

    dispatch({ type: PRESENCE_ACTION, payload: { id } });
    toast.success(data.data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      dispatch({ type: LOADING, payload: false });
    }
  }
};
export const absentAction = (id, session_id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const presence = { presence: true };
    const data = await presenceAPI(id, session_id, presence);

    dispatch({ type: PRESENCE_ACTION, payload: { id } });
    toast.success(data.data.message, {
      autoClose: 1000,
    });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const fetchUnitsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getUnits(id);

    dispatch({ type: FETCH_ALL_UNITS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const createUnitAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const { data } = await createUnit(formData);
    dispatch({ type: CREATE_UNIT, payload: data.unit });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const jwtMeetToken =
  (meeting, setShowVedioCall, setMeeting, sessionId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      await editSession({
        id: sessionId,
        meet_link: "https://meeting.intellect.tn",
      });
      const { data } = await getJwtToken(meeting);

      setMeeting({
        roomName: meeting.room,
        moderator: meeting.name,
        token: data.token,
      });
      setShowVedioCall(true);
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING, payload: false });
    }
  };
