// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Tab, Card, Nav, Breadcrumb, Button } from "react-bootstrap";
import ListReader from "./ListReader";
import PresenceManagement from "./PresenceManagement";
import QuizInSession from "./QuizInSession";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSessionAction, getReaderInSessionAction } from "actions/sessions";

import {
  startVideoSessionAction,
  startWhiteBoardAction,
} from "actions/onlineSessions";

// import sub custom components

const SessionDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("session_id");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSessionAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getReaderInSessionAction(id));
  }, [dispatch, id]);

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Session Details</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Teacher Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>My Sessions</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="all">
            <Card>
              <Card.Header className="border-bottom-0 p-0 bg-white">
                <Nav className="nav-lb-tab">
                  <Nav.Item>
                    <Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
                      List of Readers
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="approved" className="mb-sm-3 mb-md-0">
                      Presence Management
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="pending" className="mb-sm-3 mb-md-0">
                      Quizs In Session Details
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <Tab.Content>
                  <Tab.Pane eventKey="all" className="pb-4">
                    <ListReader />
                  </Tab.Pane>
                  <Tab.Pane eventKey="approved" className="pb-4">
                    <PresenceManagement />
                  </Tab.Pane>
                  <Tab.Pane eventKey="pending" className="pb-4">
                    <QuizInSession />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SessionDetails;
