// import node module libraries
import React, { Fragment, useEffect, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Table } from "react-bootstrap";

// import custom components

import Pagination from "components/elements/advance-table/Pagination";

import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";

import { getAllGameResultsAction, getGameResultsAction } from "actions/game";

const ScoreList = ({ game, book_id }) => {
  const dispatch = useDispatch();
  const { gameResults } = useSelector((state) => state.games);

  useEffect(() => {
    if (game == "over-all") {
      dispatch(getAllGameResultsAction(book_id));
    } else {
      dispatch(getGameResultsAction(game, book_id));
    }
  }, [dispatch, game, book_id]);

  const columns = useMemo(
    () => [
      {
        accessor: "rank",
        Header: "Rank",
        Cell: ({ value }) => (
          <span className="rank-student-cell">#{value}</span>
        ),
      },
      {
        accessor: "username",
        Header: "Student",
        Cell: ({ value }) => <span className="rank-student-cell">{value}</span>,
      },
      {
        accessor: "score",
        Header: "Score",
        Cell: ({ value }) => <span>{value}</span>,
      },
    ],
    []
  );

  const data = useMemo(() => gameResults, [gameResults]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex } = state;

  const title =
    game === "over-all"
      ? "Overall "
      : game === "think-word"
      ? "Think Word "
      : game === "bee-genius"
      ? "Bee Genius "
      : game === "Word-explorer"
      ? "Word Explorer "
      : "Intellect Link ";
  return (
    <Fragment>
      <h1 className="mb-1 h3 fw-bold">🏆 {title} Rankings</h1>
      {/* Table */}
      <Table
        hover
        responsive
        {...getTableProps()}
        className="text-nowrap table-centered"
      >
        <thead className="table-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
      />
    </Fragment>
  );
};

export default ScoreList;
