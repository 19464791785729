// import node module libraries
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, ListGroup } from "react-bootstrap";

// import utility file
import { numberWithCommas } from "helper/utils";

// import data files

const FollowedPacks = ({ title, data }) => {
  const [showAll, setShowAll] = useState(5);
  const handleShowAll = () => {
    if (showAll === 5) {
      setShowAll(data.length);
    } else {
      setShowAll(5);
    }
  };
  return (
    <Card className="h-100">
      <Card.Header className="d-flex align-items-center justify-content-between card-header-height">
        <h4 className="mb-0">{title}</h4>
        <Link
          to="#"
          onClick={() => handleShowAll()}
          className="btn btn-outline-secondary btn-sm"
        >
          {showAll == 5 ? "View all" : "View less"}
        </Link>
      </Card.Header>
      <Card.Body>
        <ListGroup variant="flush">
          {data
            .slice(0, showAll)
            .reverse()
            .map((item, index) => (
              <ListGroup.Item
                className={`px-0 ${index === 0 ? "pt-0" : ""}`}
                key={index}
              >
                <Row>
                  <Col className="col-auto">
                    <div
                      className={`avatar avatar-md avatar-indicators avatar-${"online"}`}
                    ></div>
                  </Col>
                  <Col className="ms-n3">
                    <h4 className="mb-0 h5">{item?.packTitle}</h4>
                    <span className="me-2 fs-6">
                      <span className="text-dark  me-1 fw-semi-bold">4</span>
                      Books
                    </span>
                    <span className="me-2 fs-6">
                      <span
                        className="  me-1 fw-semi-bold"
                        style={{ color: "green" }}
                      >
                        {numberWithCommas(item?.approvedCount)}
                      </span>
                      Approved students
                    </span>
                    <span className="fs-6">
                      {" "}
                      <span
                        className=" me-1 fw-semi-bold"
                        style={{ color: "red" }}
                      >
                        {numberWithCommas(item?.notApprovedCount)}
                      </span>{" "}
                      Not approved students
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};
export default FollowedPacks;
