// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Col, Row, Card, Tab, Breadcrumb, Nav } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ScoreList from "./ScoreList";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchAllBooksAction } from "actions/books";

const Scores = () => {
  const dispatch = useDispatch();
  const [game, setGame] = useState("bee-genius");
  const [book, setBook] = useState(null);

  const handleSelection = (selected) => {
    if (selected.length === 0) return;
    setBook(selected[0]);
  };

  const { books } = useSelector((state) => state.books);

  useEffect(() => {
    dispatch(fetchAllBooksAction());
  }, [dispatch]);
  return (
    <Fragment>
      <Tab.Container defaultActiveKey="grid">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
              <div className="mb-3 mb-md-0">
                <h1 className="mb-1 h2 fw-bold">Vocabulary Games</h1>
                <Breadcrumb>
                  <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                  <Breadcrumb.Item href="#">Games</Breadcrumb.Item>
                  <Breadcrumb.Item active>Scores</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
            <div>
              <Row>
                <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
                  <Typeahead
                    id="autocomplete-books"
                    labelKey="title"
                    options={books}
                    placeholder=" Select book..."
                    onChange={handleSelection}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Card.Header className="border-bottom-0 p-0 bg-white mb-4">
          <Nav className="nav-lb-tab">
            <Nav.Item onClick={() => setGame("over-all")}>
              <Nav.Link className="mb-sm-3 mb-md-0">Overall</Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => setGame("think-word")}>
              <Nav.Link className="mb-sm-3 mb-md-0">Think word</Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => setGame("bee-genius")}>
              <Nav.Link className="mb-sm-3 mb-md-0">Bee genius</Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => setGame("Word-explorer")}>
              <Nav.Link className="mb-sm-3 mb-md-0">Word explorer</Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => setGame("intellect-link")}>
              <Nav.Link className="mb-sm-3 mb-md-0">Intellect link</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="grid" className="pb-4">
            <ScoreList game={game} book_id={book?.id} />
          </Tab.Pane>
          <Tab.Pane eventKey="list" className="pb-4">
            <Card className="mb-5 ">
              <Card.Body className="p-0"></Card.Body>
            </Card>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <ToastContainer />
    </Fragment>
  );
};
export default Scores;
