// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
} from "react-bootstrap";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import DotBadge from "components/elements/bootstrap/DotBadge";
import {
  ApproveFollowedPackAction,
  RejectFollowedPackAction,
  deleteFollowedPackRequestAction,
  fetchAllFollowedPacksAction,
} from "actions/followedPack";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../SpinnerLoading";
import AddFollowPack from "./AddFollowPack";
import { ToastContainer } from "react-toastify";

// import data files

const PackSubscription = () => {
  const [show, setShow] = useState(false);
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const { followedpacks } = useSelector((state) => state.followedPack);
  useEffect(() => {
    dispatch(fetchAllFollowedPacksAction());
  }, [dispatch]);

  const handleApproveFollowedPackRequest = ({ user_id, pack_id }) => {
    setFollowedPackRequestToApproved({ user_id, pack_id });
    setShowApproveModal(true);
  };
  const confirmApprovedFollowedPackRequest = () => {
    if (FollowedPackRequestToApproved) {
      dispatch(ApproveFollowedPackAction(FollowedPackRequestToApproved));
      setFollowedPackRequestToApproved(null);
      setShowApproveModal(false);
    }
  };

  const handleRejectFollowedPackRequest = ({ user_id, pack_id }) => {
    setFollowedPackRequestToRejected({ user_id, pack_id });
    setShowRejectModal(true);
  };
  const confirmRejectedFollowedPackRequest = () => {
    if (FollowedPackRequestToRejected) {
      dispatch(RejectFollowedPackAction(FollowedPackRequestToRejected));
      setFollowedPackRequestToRejected(null);
      setShowRejectModal(false);
    }
  };

  const handleDeleteFollowedPackRequest = ({ user_id, pack_id }) => {
    setFollowedPackRequestToDelete({ user_id, pack_id });
    setShowDeleteModal(true);
  };

  const confirmDeleteFollowedPackRequest = () => {
    if (
      FollowedPackRequestToDelete &&
      confirmationMessage === "DELETE REQUEST"
    ) {
      dispatch(deleteFollowedPackRequestAction(FollowedPackRequestToDelete));
      setFollowedPackRequestToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [FollowedPackRequestToRejected, setFollowedPackRequestToRejected] =
    useState(null);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [FollowedPackRequestToApproved, setFollowedPackRequestToApproved] =
    useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [FollowedPackRequestToDelete, setFollowedPackRequestToDelete] =
    useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>ACTION</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            {" "}
            <Send size="18px" className="dropdown-item-icon" /> Approved
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            {" "}
            <Inbox size="18px" className="dropdown-item-icon" /> Moved Draft
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            {" "}
            <Trash size="18px" className="dropdown-item-icon" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "pack_title", Header: "Pack Title" },
      // { accessor: 'pack_id', Header: 'Pack ID' },
      { accessor: "username", Header: "Reader Name" },
      { accessor: "email", Header: "Reader Email" },
      // { accessor: 'user_id', Header: 'Reader ID' },
      {
        id: "status",
        accessor: "approved",
        Header: "STATUS",
        Cell: ({ value }) => {
          return (
            <DotBadge
              bg={value === true ? "success" : value === false ? "warning" : ""}
            ></DotBadge>
          );
        },
      },
      {
        id: "action",
        accessor: "approved",
        Header: "Action",
        Cell: ({ value, row }) => {
          if (value === false) {
            return (
              <Fragment>
                <Button
                  href="#"
                  variant="success"
                  className="btn-sm"
                  onClick={() =>
                    handleApproveFollowedPackRequest({
                      user_id: row.original.user_id,
                      pack_id: row.original.pack_id,
                    })
                  }
                >
                  Approved
                </Button>{" "}
                {userData?.role == "admin" && (
                  <Button
                    href="#"
                    variant="danger"
                    className="btn-sm"
                    onClick={() =>
                      handleDeleteFollowedPackRequest({
                        user_id: row.original.user_id,
                        pack_id: row.original.pack_id,
                      })
                    }
                  >
                    Delete
                  </Button>
                )}
              </Fragment>
            );
          }
          if (value === true) {
            return (
              <Button
                href="#"
                variant="secondary"
                className="btn-sm"
                disabled={loading}
                onClick={() =>
                  handleRejectFollowedPackRequest({
                    user_id: row.original.user_id,
                    pack_id: row.original.pack_id,
                  })
                }
              >
                Change Status
              </Button>
            );
          }
        },
      },
      /* 			{
							accessor: 'action',
							Header: 'Action',
							Cell: () => {
								return <ActionMenu />;
							}
						} */
    ],
    []
  );

  const data = useMemo(() => followedpacks, [followedpacks]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          /* 	id: 'selection',
						Header: ({ getToggleAllRowsSelectedProps }) => (
							<Checkbox {...getToggleAllRowsSelectedProps()} />
						),
						Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} /> */
        },
        ...columns,
      ]);
    }
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Pack Subscription</h1>
              <Breadcrumb>
                <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Orders</Breadcrumb.Item>
                <Breadcrumb.Item active>Pack Subscription</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <Col className="d-flex justify-content-end">
            <div style={{ marginBottom: "20px" }}>
              <AddFollowPack show={show} setShow={setShow} />
            </div>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div className=" overflow-hidden">
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    className="mb-lg-0 mb-2 px-5 py-4"
                  >
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                      placeholder="Search Pack"
                    />
                  </Col>
                </Row>
              </div>

              {loading ? (
                <SpinnerLoading />
              ) : (
                <div className="table-responsive ">
                  <Table {...getTableProps()} className="text-nowrap">
                    <thead className="table-light">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
              <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Are you sure you want to delete this request ?</p>
                  <h4 className="text-secondary">
                    you must write "DELETE REQUEST" to confirm the deletion
                  </h4>
                  <input
                    className="form-control"
                    rows="3"
                    placeholder="Enter confirmation message"
                    value={confirmationMessage}
                    onChange={(e) => setConfirmationMessage(e.target.value)}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant={
                      confirmationMessage !== "DELETE REQUEST"
                        ? "secondary"
                        : "danger"
                    }
                    disabled={confirmationMessage !== "DELETE REQUEST"}
                    onClick={confirmDeleteFollowedPackRequest}
                  >
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showApproveModal}
                onHide={() => setShowApproveModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Approve</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Are you sure you want to approve this request ?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowApproveModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    onClick={confirmApprovedFollowedPackRequest}
                  >
                    Approved
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showRejectModal}
                onHide={() => setShowRejectModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Change Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Are you sure you want to Change this status ?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowRejectModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    onClick={confirmRejectedFollowedPackRequest}
                  >
                    Change Status
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default PackSubscription;
