// import node module libraries
import React, { Fragment, useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import "./typehead.css";
import { Link } from "react-router-dom";
import { Col, Row, Button, Table, Modal, Form } from "react-bootstrap";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import DotBadge from "components/elements/bootstrap/DotBadge";
import { getSessionByTeacherAction, jwtMeetToken } from "actions/sessions";
import { reformatISODate } from "helper/utils";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { Typeahead } from "react-bootstrap-typeahead";
import { getQuizInSessionAction } from "actions/quiz";

const SessionsTable = () => {
  const { user } = useSelector((state) => state.auth);
  // const user = { id: "935" };
  const dispatch = useDispatch();
  const [api, setApi] = useState(null);
  const { sessions } = useSelector((state) => state.sessions);
  const { session_quizs } = useSelector((state) => state.quizs);
  const [showVedioCall, setShowVedioCall] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState(null);

  const handleCloseQuiz = () => {
    setShowQuiz(false);
    setSelectedQuiz(null);
  };
  const [meeting, setMeeting] = useState({
    roomName: "",
    moderator: "",
    token: "",
  });

  const handleQuizSelection = (selected) => {
    if (selected?.length > 0) {
      console.log(selected[0]._id);
      setSelectedQuiz(selected[0]._id);
    }
  };

  // After setting up Jitsi Meeting instance
  const sendQuizToMenbers = () => {
    if (api) {
      api.executeCommand("sendEndpointTextMessage", "", {
        data: {
          quiz_id: selectedQuiz,
        },
      });
      handleCloseQuiz();
    } else {
      console.error("Jitsi API is not initialized.");
    }
  };

  useEffect(() => {
    dispatch(getSessionByTeacherAction(user.id));
  }, [dispatch, user.id]);
  const handleStartOnlineSession = (session) => {
    dispatch(
      jwtMeetToken(
        {
          name: user.username,
          room: session.name.replace(/\s+/g, "_"),
          isModerator: "owner",
        },
        setShowVedioCall,
        setMeeting,
        session.id
      )
    );
    dispatch(getQuizInSessionAction({ session_id: session.id }));
  };
  const handleCloseCall = () => {
    setShowVedioCall(false);
    setMeeting({
      roomName: "",
      moderator: "",
      token: "",
    });
  };

  const columns = useMemo(
    () => [
      { accessor: "id", Header: "ID", show: false },
      {
        accessor: "name",
        Header: "Session Title",
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={`/teacher-dashboard/sessions_details?session_id=${row.original.id}`}
            >
              <div className="d-lg-flex align-items-center">
                <div className="ms-lg-3 mt-2 mt-lg-0">
                  <h5 className="mb-1 text-primary-hover">{value}</h5>
                </div>
              </div>
            </Link>
          );
        },
      },
      { accessor: "capacity", Header: "Capacity" },
      // { accessor: "book_name", Header: "Book Name" },
      { accessor: "teacher_name", Header: "Teacher Name", show: false },
      {
        accessor: "active",
        Header: "Session Status",

        Cell: ({ value, row }) => {
          return (
            <Fragment>
              <DotBadge
                bg={
                  value === false ? "warning" : value === true ? "success" : ""
                }
              >
                {value ? "Active" : "Inactive"}
              </DotBadge>
            </Fragment>
          );
        },
      },
      // { accessor: "location", Header: "Location" },
      {
        accessor: "start_date",
        Header: "Start",
        Cell: ({ value, row }) => {
          const formattedDate = reformatISODate(value);
          return (
            <div className="d-flex align-items-center">
              <div className="ms-3">
                <h5 className="mb-0">{formattedDate}</h5>
              </div>
            </div>
          );
        },
      },
      {
        accessor: "end_date",
        Header: "End",
        Cell: ({ value, row }) => {
          const formattedDate = reformatISODate(value);
          return (
            <div className="d-flex align-items-center">
              <div className="ms-3">
                <h5 className="mb-0">{formattedDate}</h5>
              </div>
            </div>
          );
        },
      },

      {
        accessor: "location",
        Header: "",
        Cell: ({ value, row }) => {
          return (
            <div>
              {value === "online" ? (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => handleStartOnlineSession(row.original)}
                >
                  Start Online Session
                </Button>
              ) : null}
            </div>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => sessions.reverse(), [sessions]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const { pageIndex, globalFilter } = state;
  const handleMeetingEnd = () => {
    setShowVedioCall(false);
  };

  // const interfaceConfigOverwrite = {
  //   TOOLBAR_BUTTONS: [
  //     "microphone",
  //     "camera",
  //     "desktop",
  //     "fullscreen",
  //     "hangup",
  //     "chat",
  //     "recording",
  //     "settings",
  //     "raisehand",
  //     "videoquality",
  //     "tileview",
  //     "invite",
  //     "custom-button", // Add the custom button ID here
  //   ],
  // };

  return (
    <Fragment>
      <div className="overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search Session"
            />
          </Col>
        </Row>
      </div>

      <div className="table-responsive overflow-y-hidden">
        <Table
          hover
          {...getTableProps()}
          className="text-nowrap table-centered"
        >
          <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Modal
        show={showVedioCall}
        onHide={handleCloseCall}
        dialogClassName="custom-modal-width"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>Meeting</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 0, height: "100vh" }}>
          <JitsiMeeting
            domain={"meeting.intellect.tn"}
            roomName={meeting?.roomName}
            jwt={meeting?.token}
            configOverwrite={{
              startWithAudioMuted: true,
              disableModeratorIndicator: true,
              startScreenSharing: true,
              enableEmailInStats: false,
              // Enable waiting room feature for participants
              startWithVideoMuted: true,
              requireDisplayName: true, // Require users to enter a display name
              prejoinPageEnabled: true, // Show pre-join page (participants wait until moderator joins)
              disableInviteFunctions: true, // Optional: Disable invite functions
              defaultLanguage: "en",
              customToolbarButtons: [
                {
                  icon: "https://i.imgur.com/FQ5xElT.png",
                  id: "add-quiz",
                  text: "Add quiz",
                },
              ],
            }}
            getIFrameRef={(iframeRef) => (iframeRef.style.height = "99%")}
            onReadyToClose={handleMeetingEnd}
            onApiReady={(api) => {
              setApi(api);
              api.addListener("toolbarButtonClicked", (event) => {
                if (event.key === "add-quiz") {
                  setShowQuiz(true);
                }
              });
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showQuiz} onHide={() => handleCloseQuiz()}>
        <Modal.Header style={{ backgroundColor: "#141414" }} closeButton>
          <Modal.Title style={{ color: "white" }}>Add Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#141414" }}>
          <Form.Group className="mb-3">
            <Form.Label style={{ color: "white" }}>Quiz</Form.Label>
            <Typeahead
              className="custom-typeahead"
              id="autocomplete-quiz"
              labelKey="title"
              options={session_quizs}
              placeholder="Type to search quiz..."
              onChange={(selected) => handleQuizSelection(selected)}
            />

            <Form.Text className="text-muted">
              Field must contain a unique value
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#141414" }}>
          <Button
            style={{ backgroundColor: "#246FE5", borderColor: "#246FE5" }}
            type="submit"
            variant="primary"
            id="add-new-event-btn"
            // disabled={loading}
            // onClick={(e) => handleAddQuiz(e)}
            onClick={sendQuizToMenbers}
          >
            Add Quiz
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
      />
    </Fragment>
  );
};

export default SessionsTable;
