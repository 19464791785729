import axios from "axios";
import { generateCloudinarySignature } from "helper/utils";
axios.defaults.withCredentials = true;
// import cloudinary from "cloudinary/lib/cloudinary";
// const API = axios.create({ baseURL: "http://localhost:5003" });

const API = axios.create({ baseURL: "https://api.iread.education" });
const QUIZ_API = axios.create({
  baseURL: "https://quiz.iread.education/",
  // baseURL: "http://localhost:5044",
  withCredentials: false,
});

const NOTIFICATION_API = axios.create({
  baseURL: "https://notification.iread.tn",
  withCredentials: false,
});
const JSON_API = axios.create({
  baseURL: "https://json.iread.tn",
  withCredentials: false,
});
const INVOICE_API = axios.create({
  baseURL: "https://invoicing.iread.education",
  withCredentials: false,
});
const KEY_INVOICE = "65ba69fb713e132120743444";
export const NOTIFICATION_KEY = "657041384453ef425e2e426f";
// const QUIZ_API = axios.create({
//   baseURL: "http://quiz.iread.tn/api",
//   headers: {
//     "Content-Type": "multipart/form-data",
//   },
// });
const SPRINTUP_API = axios.create({
  baseURL: "https://api-sprintup.intellect.tn",
  // baseURL: "http://localhost:5034",

  withCredentials: false,
});
const KEY_QUIZ = "65800f77a2ce2e2c88ebd8bd";
const CLOUD_NAME = "dtwkikmuy";
const API_KEY = "564314396825113";
const API_SECRET = "jSW_GSXsGujJUSwWP7rjlCIV4TU";
const cloudinaryAPI = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/destroy`;
const TIMESTAMP = Math.round(new Date().getTime() / 1000);

//user
export const signIn = (fromData) => API.post("/reader/login", fromData);
export const signUp = (fromData) => API.post("/reader/register", fromData);
export const logOut = () => API.get("/reader/logout");
export const current = () => API.get("/reader/user_authenticated");
export const resendLink = () =>
  API.post("/reader/resend_email_confirmation_link");
export const deleteImage = async (publicId) => {
  const signatureParams = {
    public_id: publicId,
    timestamp: TIMESTAMP,
    api_key: API_KEY,
  };
  const signature = generateCloudinarySignature(signatureParams);
  try {
    console.log({
      public_id: "v1691521278/x9gmc0bdxupp647hhmrf",
      signature: signature,
      api_key: API_KEY,
      timestamp: TIMESTAMP,
    });
    return await axios.post(cloudinaryAPI, {
      public_id: publicId,
      signature: signature,
      api_key: API_KEY,
      timestamp: TIMESTAMP,
    });
  } catch (error) {
    console.log(error);
  }
};

//packs
/* export const getAllPacks = () => API.get("/main/show_all_pack");
export const getAllPackDetails = (id) =>
  API.post("/main/get_pack_details", { id });*/

//Users
export const getAllUsers = () => API.get("/admin/show_all_readers");
export const getFollowers = (id) => API.get("/admin/followers/" + id);
export const getUserById = (id) => API.get("/admin/get_user/" + id);
export const editUser = (fromData) => API.put("/admin/update_user", fromData);
export const deleteUser = (id) => API.post("/admin/delete_user", { id });
export const createUser = (fromData) =>
  API.post("/admin/create_user", fromData);

//Approved
export const approveUser = (id) => API.post(`/admin/approved_user`, { id });
export const approveTeacher = (id) => API.post(`/admin/approved_user`, { id });

//Teachers
export const getAllTeachers = (fromData) =>
  API.get("/admin/show_all_teachers", fromData);
export const getTeacherById = (id) => API.get("/admin/get_user/" + id);
export const editTeacher = (fromData) =>
  API.put("/admin/update_teacher", fromData);
export const deleteTeacher = (id) => API.post("/admin/delete_user", { id });
export const createTeacher = (fromData) =>
  API.post("/admin/create_teacher", fromData);

//Assistant
export const getAllAssistants = (fromData) =>
  API.get("/admin/show_all_assistants", fromData);
export const getAssistantById = (id) => API.get("/admin/get_user/" + id);
export const editAssistant = (fromData) =>
  API.put("/admin/update_user", fromData);
export const deleteAssistant = (id) => API.post("/admin/delete_user", { id });
export const createAssistant = (fromData) =>
  API.post("/admin/create_assistant", fromData);

//Packs
export const getAllPacks = (fromData) =>
  API.get("/main/show_all_pack", fromData);
export const getPackById = (id) => API.post("/main/get_pack_details", { id });
export const editPack = (fromData) =>
  API.post("/admin/update_pack_details", fromData);
export const deletePack = (id) => API.post("/admin/delete_pack", { id });
export const createPack = (fromData) =>
  API.post("/admin/create_pack", fromData);
export const getAllBooksInPack = (id) =>
  API.post("/main/get_books_from_pack", { id });
//Books
export const addBookInPack = (book_id, pack_id) =>
  API.post("admin/add_book_to_pack", { book_id, pack_id });
export const deleteBookFromPack = (book_id, pack_id) =>
  API.post("admin/delete_book_from_pack", { book_id, pack_id });
export const generateCode = (num_codes, pack_id) =>
  API.post("/admin/generate_code_in_pack/" + pack_id, { num_codes });

//Books
export const getAllBooks = (fromData) =>
  API.get("/admin/show_all_books", fromData);
export const getBookById = (id) => API.get("/admin/get_book/" + id);
export const editBook = (fromData) => API.put("/admin/update_book", fromData);
export const deleteBook = (id) => API.post("/admin/delete_book", { id });
export const createBook = (fromData) =>
  API.post("/admin/create_book", fromData);

//Sessions
export const getAllSessions = (fromData) =>
  API.get("/admin/sessions", fromData);
export const getSessionById = (id) => API.get("/admin/sessions/" + id);
export const editSession = (fromData) =>
  API.post("/admin/update_session", fromData);
export const deleteSession = (id) => API.post("/admin/delete_session", { id });
export const createSession = (fromData) =>
  API.post("/admin/create_session", fromData);
export const getSessionsFromBook = (book_id) =>
  API.post("/admin/sessions_in_book", { book_id });
export const getSessionByTeacher = (id) =>
  API.get("/admin/sessions_by_teacher/" + id);
export const getReaderInSession = (id) =>
  API.get("/admin/reader_in_session/" + id);
export const presenceAPI = (id, session_id, presence) =>
  API.put("/admin/update_presence/" + session_id + "/" + id, presence);

export const getUnits = (id) => API.get("/admin/show_all_units/" + id);
export const createUnit = (formData) => API.put("/admin/create_unit", formData);
//FollowedPacks

export const getAllFollowedPacks = (fromData) =>
  API.get("/admin/show_pack_follow_requests", { fromData });
export const approveFollowedPack = (fromData) =>
  API.post("/admin/approve_pack_follow_request", fromData);
export const deleteFollowedPack = (fromData) =>
  API.post("/admin/delete_follow_request", fromData);
export const rejectFollowedPack = (fromData) =>
  API.post("/admin/reject_pack_follow_request", fromData);

export const createFollowedPack = (fromData) =>
  API.post("/admin/create_follow_pack", fromData);

//FollowedSessions

export const getAllFollowedSessions = (fromData) =>
  API.get("/admin/show_session_follow_requests", { fromData });
export const approveFollowedSession = (fromData) =>
  API.post("/admin/approve_session_follow_request", fromData);
export const deleteFollowedSession = (fromData) =>
  API.post("/admin/delete_session_follow_request", fromData);
export const rejectFollowedSession = (fromData) =>
  API.post("/admin/reject_session_follow_request", fromData);
export const addUserToSession = (fromData) =>
  API.post("/reader/add_user_to__session", fromData);
export const removeUserFromSession = (fromData) =>
  API.post("/reader/remove_user_from_session", fromData);
export const createFollowedSession = (fromData) =>
  API.post("/admin/create_follow_session", fromData);
//about book
export const getAboutBook = (id) => API.get("/admin/get_about_book/" + id);
export const createAboutBook = (fromData, id) =>
  API.post("/admin/create_about_book/" + id, fromData);
export const updateAboutBook = (fromData, id) =>
  API.put("/admin/update_about_book/" + id, fromData);
export const deleteAboutBook = (id) =>
  API.delete("/admin/delete_about_book/" + id);
//book text
export const getBookText = (id) => API.get("/admin/book_text/" + id);
export const createBookText = (fromData) =>
  API.post("/admin/book_text", fromData);
export const updateBookText = (id, formData) =>
  API.put("/admin/book_text/" + id, formData);

// Quizs

export const fetchAllQuizs = () => QUIZ_API.get("/quiz/" + KEY_QUIZ);
export const fetchOneQuiz = (id) => QUIZ_API.get("/quiz/get_one/" + id);

export const deleteQuiz = (id) => QUIZ_API.delete("/quiz/" + id);
export const importQuiz = (formData) =>
  API.post("/reader/import-quiz-json", formData);
export const assignQuizToUser = (formData) =>
  API.post("/reader/assign_quiz_to_user", formData);
export const addQuizToSession = (formData) =>
  API.post("/admin/add_quiz_to_session", {
    session_id: formData.session_id,
    quiz_token: formData.quiz_token,
  });
export const deleteQuizFromSession = (formData) =>
  API.post("/admin/delete_quiz_from_session", formData);
export const getQuizsInSession = (formData) =>
  API.post("/admin/get_quiz_in_session", formData);
export const getQuizByToken = (formData) =>
  API.post("/reader/quiz_by_token", formData);
export const getResult = (token, user_id) => {
  return API.post("/reader/result", { token, user_id });
};

export const getQuiz = (quiz_id) => {
  return QUIZ_API.get("/quiz/get_one/" + quiz_id);
};
export const getQuestion = (question_id) => {
  return QUIZ_API.get("/question/" + question_id);
};
export const createQuestion = (question) => {
  return QUIZ_API.post("/question/", { ...question, app: KEY_QUIZ });
};

export const getQuizAnswer = (fromData) => {
  return QUIZ_API.post("/userAnswer/get_one", fromData);
};

export const getEssayQuestion = (token, user_id) =>
  API.post("/reader/get-essay-answer", {
    user_id: Number(user_id),
    token: token,
  });
export const validationEssay = (fromData) =>
  API.post("/reader/validate-essay-answer", fromData);

export const createQuiz = (fromData) =>
  QUIZ_API.post("/quiz/" + KEY_QUIZ, fromData);
export const updateQuiz = (fromData) =>
  QUIZ_API.post("/quiz/update/" + KEY_QUIZ, fromData);

export const createQuestions = (fromData) =>
  QUIZ_API.post("/question/create_questions/" + KEY_QUIZ, fromData);
export const getQuestionByCat = (category) =>
  QUIZ_API.post("/question/by_book", category);

export const updateQuestion = (id, question) =>
  QUIZ_API.put("/question/" + id, question);
export const deleteQuestion = (id) => QUIZ_API.delete("/question/" + id);
export const getCheckedQuestions = () =>
  QUIZ_API.post("/question/checked_questions");
//Codes
export const fetchCodes = (id) => API.get("/admin/code_in_pack/" + id);
export const updateCode = (id, fromData) =>
  API.put("/admin/update_code/" + id, fromData);

//Dashboard admin

export const fetchAnalytics = () => API.get("/admin/get_dashboard_analytics");

//notification

export const fetchNotificationsCat = () =>
  NOTIFICATION_API.get("/category/" + NOTIFICATION_KEY);

export const createNotificationCat = (cat) =>
  NOTIFICATION_API.post("/category/", { ...cat, app: NOTIFICATION_KEY });

export const deleteNotificationCat = (id) =>
  NOTIFICATION_API.delete("/category/" + id);

export const updateNotificationCat = (cat, id) =>
  NOTIFICATION_API.put("/category/" + id, cat);

export const fetchOneNotificationCat = (id) =>
  NOTIFICATION_API.get("/category/get_one/" + id);

export const fetchNotifications = (page) =>
  NOTIFICATION_API.get(
    "/notification?page=" + page + "&app=" + NOTIFICATION_KEY
  );

export const createNotification = (noti) =>
  NOTIFICATION_API.post("/notification/", { ...noti, app: NOTIFICATION_KEY });
export const deleteNotification = (id) =>
  NOTIFICATION_API.delete("/notification/" + id);
export const updateNotification = (noti, id) =>
  NOTIFICATION_API.put("/notification/" + id, noti);
export const fetchOneNotification = (id) =>
  NOTIFICATION_API.get("/notification/get_one/" + id);

export const createNotificationUser = (noti) =>
  API.post("/admin/create_notification", noti);
export const deleteNotificationUser = (id) =>
  API.post("/admin/delete_notification", { id });
export const fetchNotificationsForUser = (id) =>
  API.post("/admin/get_notification", { user_id: id });

export const fetchUsersInPack = (pack_id) =>
  API.post("/admin/get_users_in_pack", { pack_id });

/* ADAPTATIVE LEARNINIG */

export const splitStory = (fromData) =>
  API.post("/admin/paser_story", fromData);
export const getWord = (word) => API.post("/admin/get_word", { word });

// QUIZ Category
export const getAllCategories = () => QUIZ_API.get("/category/" + KEY_QUIZ);
export const getCategoryById = (id) => QUIZ_API.get("/category/get_one/" + id);
export const editCategory = (id) => QUIZ_API.put("/category/", id);
export const deleteCategory = (id) => QUIZ_API.delete("/category/", { id });
export const createCategory = (fromData) =>
  QUIZ_API.post("/category/", { ...fromData, app: KEY_QUIZ });
// JSON API

export const saveJson = (data) => JSON_API.post("/save-json", data);
export const getJson = (title) => JSON_API.get("/get-json/" + title);
export const updateJson = (data, title) =>
  JSON_API.put("/update-json/" + title, data);

export const getJsonList = (id) => JSON_API.get("/json-list/" + id);

export const deleteJsonFile = (name) => JSON_API.delete("/delete-json/" + name);

//online session
export const startVideoSession = (id) => API.post("" + id);
export const startWhiteBoard = (id) => API.post("" + id);

//Invoices
export const getAllInvoices = () =>
  INVOICE_API.get("/invoice?app=" + KEY_INVOICE);
export const getInvoice = (id) => INVOICE_API.get("/invoice/" + id);
//shcools
//shcool
export const fetchOneShcool = (id) => API.get("/admin/get_one_shcool/" + id);
export const fetchallShcools = () => API.get("/admin/get_all_shcools");
export const updateShcool = (shcool, id) =>
  API.put("/admin/update_shcool/" + id, shcool);
export const createShcool = (shcool) =>
  API.post("/admin/create_shcool", shcool);
export const deleteShcool = (id) => API.delete("/admin/delete_shcool/" + id);
//games

export const getGameResults = (game, book_id) =>
  API.get("/reader/game-results?game=" + game + "&book_id=" + book_id);
export const getAllGameResults = (book_id) =>
  API.get("/reader/game-results-all?book_id=" + book_id);

//template
export const generateTemplate = (id) =>
  API.get("/admin/generate_template/" + id);
export const importTemplate = (id) => API.get("/admin/import_template/" + id);
export const getOneTemplate = (id) => API.get("/admin/pack_templates/" + id);
export const getTemplates = () => API.get("/admin/pack_templates");
export const getPacksByShcool = (school) =>
  API.get("reader/get_packs_by_school?school=" + school + "&all=1");
// SPRINTUP_API
export const getJwtToken = (meeting) =>
  SPRINTUP_API.post("jisti-token", meeting);
