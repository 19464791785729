import {
  ADD__QUIZ,
  ADD__QUIZS_TO_SESSION,
  CLEAR__QUIZ,
  CREATE_QUESTION,
  CREATE_QUESTIONS,
  DELETE_QUESTION,
  DELETE_QUIZ,
  DELETE__QUIZS_FROM_SESSION,
  GET_ALL_QUIZS,
  GET_ESSAY_QUESTIONS,
  GET_ONE_QUIZ,
  GET_RESULT_FOR_EACH_READER,
  GET_RESULT_IN_SESSION,
  GET__QUIZS_IN_SESSION,
  QUESTION,
  UPDATE_QUESTION,
  UPDATE_QUIZ,
} from "actions/constants";

const quizsReducer = (
  state = {
    quizs: [],
    quizEdited: {},
    session_quizs: [],
    results: [],
    essayQuestions: [],
    resultsForEachReader: [],
    storyQuestions: [],
    quiz: {
      title: "",
      desc: "",
      pass_mark: 70,
      duration: 20,
      questions: [],
    },
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_QUIZS:
      return { ...state, quizs: action?.payload };
    case GET_ONE_QUIZ:
      return { ...state, quizEdited: action?.payload };
    case UPDATE_QUIZ:
      return {
        ...state,
        quizs: [
          ...state.quizs.filter((e) => e._id !== action.payload.id),
          action.payload,
        ],
        quizEdited: {},
      };
    case DELETE_QUIZ:
      return {
        ...state,
        quizs: state.quizs.filter((e) => e._id !== action.payload),
      };
    case GET__QUIZS_IN_SESSION:
      return { ...state, session_quizs: action?.payload };
    case GET_RESULT_FOR_EACH_READER:
      return { ...state, resultsForEachReader: action?.payload };
    case GET_ESSAY_QUESTIONS:
      return { ...state, essayQuestions: action?.payload };
    case GET_RESULT_IN_SESSION:
      return { ...state, results: action.payload };
    case ADD__QUIZS_TO_SESSION:
      return {
        ...state,
        session_quizs: [...state.session_quizs, action?.payload],
      };

    case ADD__QUIZ:
      return {
        ...state,
        quiz: action.payload,
      };
    case CLEAR__QUIZ:
      return {
        ...state,
        quiz: {
          title: "",
          desc: "",
          pass_mark: 70,
          duration: 20,
          questions: [],
        },
      };

    case DELETE__QUIZS_FROM_SESSION:
      return {
        ...state,
        session_quizs: state.session_quizs.filter(
          (e) => e._id !== action.payload
        ),
      };
    case QUESTION:
      return {
        ...state,
        storyQuestions: action.payload,
      };
    case DELETE_QUESTION:
      return {
        ...state,
        storyQuestions: state.storyQuestions.filter(
          (q) => q._id != action.payload
        ),
      };
    case UPDATE_QUESTION:
      return {
        ...state,
        storyQuestions: state.storyQuestions.map((q) => {
          if (q._id == action.payload._id) {
            return action.payload;
          } else {
            return q;
          }
        }),
      };
    case CREATE_QUESTIONS:
      return {
        ...state,
        storyQuestions: [...action.payload, state.storyQuestions],
      };
    case CREATE_QUESTION:
      return {
        ...state,
        storyQuestions: [...state.storyQuestions, action.payload],
      };

    default:
      return state;
  }
};

export default quizsReducer;
