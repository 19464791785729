// import node module libraries
import React, { Fragment, useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Image,
  Dropdown,
  Table,
  Modal,
} from "react-bootstrap";
import { MoreVertical, Trash, Edit, Eye } from "react-feather";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";

import {
  fetchAllBooksAction,
  deleteBookAction,
  getAboutBookAction,
  createAboutBookAction,
  updateAboutBookAction,
  deleteAboutBookAction,
} from "actions/books";
import BookTextModal from "./BookTextModal";

const booksTable = ({ setBookId }) => {
  const navigate = useNavigate();
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const dispatch = useDispatch();
  const [book_id, setBook_id] = useState(null);
  const [bookTitle, setBookTitle] = useState("");
  const [showText, setShowText] = useState(false);
  const { books, about_book } = useSelector((state) => state.books);

  useEffect(() => {
    dispatch(fetchAllBooksAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAboutBookAction(book_id));
  }, [dispatch, book_id]);

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file && !book_id) {
      alert("Please select a JSON file");
    } else {
      const formData = new FormData();
      formData.append("jsonFile", file);
      dispatch(createAboutBookAction(formData, book_id));
    }
  };
  const handleUpdateUpload = async () => {
    if (!file && !book_id) {
      alert("Please select a JSON file");
    } else {
      const formData = new FormData();
      formData.append("jsonFile", file);
      dispatch(updateAboutBookAction(formData, book_id));
    }
  };
  const handleDeleteIntro = async () => {
    if (!book_id) {
      alert("There is no Intro selected");
    } else {
      dispatch(deleteAboutBookAction(book_id));
    }
  };

  const handleDeleteBook = (id) => {
    setBookToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDeleteBook = () => {
    if (bookToDelete && confirmationMessage === "DELETE BOOK") {
      dispatch(deleteBookAction(bookToDelete));
      setBookToDelete(null);
      setShowDeleteModal(false);
      setConfirmationMessage("");
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showBookeModal, setShowBookModal] = useState(false);

  const [bookToDelete, setBookToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const handleshowAbout = (id) => {
    setBook_id(id), setShowBookModal(true);
  };
  const handleCloseShowBook = () => {
    setBook_id(null), setShowBookModal(false);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));

  const ActionMenu = ({ id, setBookId, title }) => {
    return (
      <Dropdown style={{ zIndex: 1000, position: "absolute" }}>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          <Dropdown.Item eventKey="1" onClick={() => setBookId(id)}>
            {" "}
            <Edit size="15px" className="dropdown-item-icon" /> Edit
          </Dropdown.Item>
          <Dropdown.Item eventKey="2" onClick={() => handleshowAbout(id)}>
            {" "}
            <Eye size="15px" className="dropdown-item-icon" /> Book introduction
          </Dropdown.Item>
          {userData?.role == "admin" && (
            <Dropdown.Item eventKey="3" onClick={() => handleDeleteBook(id)}>
              {" "}
              <Trash
                size="15px"
                className="dropdown-item-icon"
                variant="danger"
                onClick={confirmDeleteBook}
                disabled={confirmationMessage !== "DELETE BOOK"}
              />
              Remove
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: "id", Header: "ID", show: false },
      {
        accessor: "title",
        Header: "Books",
        Cell: ({ value, row }) => {
          return (
            <div
              className="d-lg-flex align-items-center"
              onClick={() =>
                navigate(
                  `/books-management/book-details/${row?.original?.id}?book_title=${row?.original?.title}`
                )
              }
            >
              <div>
                <Image
                  src={row.original.img}
                  alt=""
                  className="img-4by3-lg rounded"
                />
              </div>
              <div className="ms-lg-3 mt-2 mt-lg-0">
                <h4 className="mb-1 text-primary-hover">{value}</h4>
                <span className="text-inherit">
                  <h7 className="text-inherit">
                    Released in {row.original.release_date}
                  </h7>
                </span>
              </div>
            </div>
          );
        },
      },
      { accessor: "date_added", Header: "", show: false },
      {
        accessor: "author",
        Header: "Author",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex align-items-center">
              <h5 className="mb-0">{value}</h5>
            </div>
          );
        },
      },

      //page number section
      { accessor: "page_number", Header: "Page number" },
      //category section
      { accessor: "category", Header: "Category" },

      {
        id: "action",
        accessor: "id",
        Header: "Action",

        Cell: ({ row }) => {
          if (userData?.role !== "teacher") {
            return (
              <ActionMenu
                id={row.original.id}
                setBookId={setBookId}
                title={row.original.title}
              />
            );
          }
        },
      },
    ],
    []
  );

  const data = useMemo(() => books, [books]) || [];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Fragment>
      <div className="overflow-hidden">
        <Row>
          <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              placeholder="Search Book"
            />
          </Col>
        </Row>
      </div>

      <div className="table-responsive overflow-y-hidden">
        <Table
          hover
          {...getTableProps()}
          className="text-nowrap table-centered"
        >
          <thead className="table-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        style={{ cursor: "pointer" }}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this book?</p>
          <h4 className="text-secondary">
            you must write "DELETE BOOK" to confirm the deletion
          </h4>
          <input
            className="form-control"
            rows="3"
            placeholder="Enter confirmation message"
            value={confirmationMessage}
            onChange={(e) => setConfirmationMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button
            variant={
              confirmationMessage !== "DELETE BOOK" ? "secondary" : "danger"
            }
            disabled={confirmationMessage !== "DELETE BOOK"}
            onClick={confirmDeleteBook}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showBookeModal} onHide={() => setShowBookModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Book introduction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!about_book ? (
            <Button variant="primary" onClick={() => handleUpload()}>
              Create introduction
            </Button>
          ) : (
            <Button variant="primary" onClick={() => handleUpdateUpload()}>
              Edit introduction
            </Button>
          )}
          <Button
            variant="danger"
            style={{ marginLeft: "8px" }}
            onClick={() => handleDeleteIntro()}
            disabled={!about_book}
          >
            Delete
          </Button>
          <div style={{ margin: "10px 0 " }}>
            <input
              className="form-control"
              type="file"
              accept=".json"
              onChange={handleFileChange}
            />
          </div>
          {about_book?.map((e, index) => (
            <div key={index}>
              <h4 className="text-secondary">{e.title}</h4>
              <p style={{ whiteSpace: "pre-line" }}>{e.desc}</p>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseShowBook()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Pagination @ Footer */}
      <Pagination
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        nextPage={nextPage}
      />
      <BookTextModal
        show={showText}
        setShow={setShowText}
        id={book_id}
        setBook_id={setBook_id}
        title={bookTitle}
        setBookTitle={setBookTitle}
      />
    </Fragment>
  );
};

export default booksTable;
